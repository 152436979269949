<template>
  <div class="homepage-feature-card ">
    <div class="bold-text">
      {{ feature.title }}
    </div>
    <div class="card-feature">
      <div
        id="feature__tracking"
        :style="isMobileSize ? maxWidth : ''"
      >
        <BaseInput
          v-for="(track, idx) in listInput"
          :key="`${feature.name}-${idx}`"
          v-model.trim="track.track"
          :name="`${feature.name}-${idx}`"
          :placeholder="feature.info"
          :class-name="classNameInput"
          :show-del-button="showDel"
          :custom-class="customClassAddMore"
          @input="handleInput(idx)"
        >
          <template #icon>
            <img
              v-if="showDel"
              :src="`/images/icon-close.svg`"
              alt="del-icon.svg"
              class="input-img"
              @click="eventDeleteBox(idx)"
            >
          </template>
        </BaseInput>
      </div>
      <div v-if="listInput.length <= 1">
        <Button
          :text="feature.name"
          type-class="red"
          @click="goingToTrack()"
        />
      </div>
    </div>
    <div
      v-show="listInput.length < 5"
      id="Tambah_1st"
      class="card-feature add"
      @click="addMoreBox()"
    >
      <div class="pointer-events-none">
        <img
          class="icon-clickable pointer-events-none"
          :src="`/images/icon-add.svg`"
          alt="icon-add.svg"
          height="16"
          width="16"
        >
      </div>
      <div class="text-click pointer-events-none">
        Tambah
      </div>
    </div>
    <div
      v-if="showDel"
      class="card-feature-between mt-3"
    >
      <div>
        <Button
          text="Batal"
          type-class="outline-red"
          :custom-padding="true"
          @click="cancelTracking()"
        />
      </div>
      <div>
        <Button
          :text="feature.name"
          :custom-padding="true"
          type-class="red"
          @click="goingToTrack()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../../../../components/new-button/Button';
import BaseInput from '../../../../components/new-input/Input';
import mixinMobile from '../../../../misc/mixinMobile';

export default {
  name: 'Track',
  components: { Button, BaseInput },
  mixins: [mixinMobile],
  data() {
    return {
      feature: {
        title: 'Lacak Pengiriman',
        name: 'Lacak',
        info: 'Masukkan nomor resi atau STT',
      },
      /**
       * @type {Array<Object>}
       */
      listInput: [
        { track: '' },
      ],
      /**
       * @type {Boolean}
       */
      showDel: false,
      maxWidth: 'max-width: 100%;',
      identifierFormID: '#feature__tracking div',
      classNameInput: '',
      customClassAddMore: 'mb-2',
    };
  },
  created() {
    if (this.isSsr()) return;
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    suffix_of(i) {
      var j = i % 10,
      k = i % 100;
      if (j == 1 && k != 11) {
        return i + 'st';
      }
      if (j == 2 && k != 12) {
        return i + 'nd';
      }
      if (j == 3 && k != 13) {
        return i + 'rd';
      }
      return i + 'th';
    },
    /**
     * create more box and push into the list input default
     */
    addMoreBox() {
      this.showDel = true;
      this.maxWidth = 'max-width: 100%;';
      this.classNameInput = 'input-xl';
      this.customClassAddMore = 'mb-2';
      this.listInput.push({ track: '' });
      document.querySelector('[id^="Tambah_"]').id = 'Tambah_' + this.suffix_of(this.listInput.length);
    },
    /**
     * event to delete box of input
     */
    eventDeleteBox(index) {
      this.listInput.splice(index, 1);
      if (this.listInput.length === 1) {
        this.showDel = false;
        this.classNameInput = '';
        this.maxWidth = 'max-width: 100%;';
      }
      document.querySelector('[id^="Tambah_"]').id = 'Tambah_' + this.suffix_of(this.listInput.length);
    },
    cancelTracking() {
      this.showDel = false;
      this.maxWidth = 'max-width: 100%;';
      const listTrack = this.listInput.filter(v => v.track !== '');
      if (listTrack.length > 0) {
        this.listInput = [listTrack[0]];
      } else {
        this.listInput = [{ track: '' }];
      }
      this.classNameInput = '';
      document.querySelector('[id^="Tambah_"]').id = 'Tambah_1st';
    },
    goingToTrack() {
      const trackNumbers = [];
      this.listInput.forEach((v) => {
        if (v.track !== '') {
          trackNumbers.push(v.track);
        }
      });
      let goToLink = '/track/stt?q=';
      if (trackNumbers.length > 0) {
        goToLink += trackNumbers.join(',');
        this.$router.push(goToLink);
      }
    },
    handleInput(index) {
      this.listInput[index].track = this.listInput[index].track.toUpperCase();
    },
    onResize() {
      this.isBigScreen = window.innerWidth >= 991;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "track";
@import "../../../../assets/css/feature/style";
</style>
