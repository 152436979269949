<template>
  <div id="base-promo">
    <div class="new-section__item__child promo">
      <div class="promo__title">
        Ada Promo Menarik, Lho!
      </div>
      <div
        class="promo__others"
        @click="$router.push('/promo')"
      >
        <span style="margin: auto;">Lihat Semua</span>
        <img
          :src="Chevron"
          alt="icon chevron lion parcel"
          width="30"
          height="31"
        >
      </div>
    </div>
    <div
      v-if="!isLoadingToFetch"
      class="new-section__item__child animation-fade-in"
    >
      <Carousel
        :slider-dot-position="setAlignDot"
        :name="carouselName"
        :size="bigScreen ? listChunkPromo.length - 1 : listChunkPromo.length"
        :total-all-data="promos.length"
        color-slider="redWithRound"
        :shimmer="false"
        :is-absolute="true"
        margin-slider="auto -35px"
      >
        <template #item>
          <div
            v-for="(slide, index) in listChunkPromo"
            :id="`${carouselName}-${index + 1}`"
            :key="`${index}-key`"
            class="slide-number"
          >
            <div
              v-for="(promo, j) in slide"
              :id="`id-${carouselName}-${index}-${j}`"
              :key="`key-${index}-${j}`"
              :class="`slide-item class-${carouselName}`"
              style="cursor: pointer;"
              @click="goToLink(promo)"
            >
              <div class="new-slide-image">
                <ImageLazy
                  :id="`program-promo-${j}`"
                  :src="promoImage(promo)"
                  :alt="promoAlt(promo)"
                />
              </div>
            </div>
          </div>
        </template>
      </Carousel>
    </div>
    <div
      v-else
      style="text-align: center"
      class="new-section__item__child"
    >
      <div
        v-if="isMobileWidth"
        class="skeleton-box"
      />
      <div
        v-for="sk in defaultSize"
        v-else
        :key="sk"
        class="skeleton-box"
      />
    </div>
  </div>
</template>

<script>
import ImageLazy from '@/components/ImageLazy/index.vue';
import Carousel from '../../../components/new-carousel/Carousel';
import { TypePromo } from '@/app/enum/promo.js';
import {
  chunkArray,
  generateChunkCarouselResponsive,
  listenerWindowResize,
  MEDIA,
  removeListenerResize,
} from '../../../components/new-carousel/util.js';
import PromoUseCase from '@/app/usecase/promo.js';

export default {
  name: 'Promo',
  components: { Carousel, ImageLazy },
  data() {
    return {
      Chevron: '/images/chevron_right_red.svg',
      carouselName: 'home',
      promos: [],
      isLoadingFetchData: false,
      isMobileWidth: false,
      defaultSize: 4,
      bigScreen: false,
    };
  },
  computed: {
    listChunkPromo() {
      if (this.isMobileWidth) {
        return chunkArray(this.promos.slice(0, 6), 1);
      }
      return chunkArray(this.promos, 3);
    },
    setAlignDot() {
      return this.isMobileWidth ? 'start' : 'center';
    },
    isLoadingToFetch() {
      return this.isLoadingFetchData;
    },
  },
  async created() {
    if (!this.isSsr()) {
      this.isMobile();
    }
    await this.fetchData();
  },
  beforeDestroy() {
    removeListenerResize(this.isMobile);
  },
  async mounted() {
    listenerWindowResize(this.isMobile);
  },
  methods: {
    isMobile() {
      this.isMobileWidth = generateChunkCarouselResponsive(window.innerWidth) === MEDIA().MOBILE;
    },
    goToLink(promo) {
      if (promo.program_type === TypePromo.CONTENT) {
        this.$router.push(`/promo/detail/${promo.program_slug}`);
      } else {
        window.open(promo.program_url, '__blank');
      }
    },
    async fetchData() {
      this.isLoadingFetchData = true;
      if (this.$store.state.promo.promos.length > 0) {
        this.promos = this.$store.state.promo.promos;
        this.isLoadingFetchData = false;
        return;
      }
      const data = await PromoUseCase.getAll(1, 16);
      if (data.err === null) {
        const promos = data.data.slice(0, 16);
        this.promos = promos;
        this.isLoadingFetchData = false;
        this.$store.commit('SET_PROMOS', promos);
      } else {
        this.isLoadingFetchData = false;
      }
    },
    promoImage(promo) {
      if (this.isMobileWidth) {
        return (
          promo.attachment_program_subphoto_url
          || promo.attachment_program_photo_url
        );
      }
      return promo.attachment_program_photo_url;
    },
    promoAlt(promo) {
      if (this.isMobileWidth) {
        return (
          promo.alt_program_subphoto_url
          || promo.alt_program_photo_url
        );
      }
      return promo.alt_program_photo_url;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
