<template>
  <div class="why-us-section">
    <div class="why-us-section__item">
      <span> Mengapa Kami ? </span>
    </div>
    <div class="why-us-section__item">
      <div
        v-for="(data, index) in finalListData"
        :key="data.title"
        class="why-us-section__item__child"
      >
        <div class="why-us-section__item__child__inner">
          <ImageLazy
            :id="`illustration-${index}`"
            :src="data.img"
            alt="illustration about us"
            height="105px"
            width="105px"
          />
        </div>
        <div class="why-us-section__item__child__inner why-us-section__item__child__desc">
          <div class="why-us-section__item__child__inner__title">
            <div class="why-us-section__item__child__inner__title__main">
              {{ data.title }}
            </div>
            <div class="why-us-section__item__child__inner__title__second">
              {{ data.motto }}
            </div>
          </div>
          <div class="why-us-section__item__child__inner__body">
            {{ data.body }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixinMobile from '@/misc/mixinMobile';
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: 'WhyUs',
  components: { ImageLazy },
  mixins: [mixinMobile],
  data() {
    return {
      listData: [
        {
          img: '/images/why_us_1.svg',
          title: 'Reliable',
          motto: 'Customer Service bisa diandalkan',
          body: 'Pantau, cari informasi produk maupun klaim melalui customer '
              + 'care Lion Parcel dengan mudah. Hubungi CS kami di +62-21-80820072.',
        },
        {
          img: '/images/why_us_2.svg',
          title: 'Fast',
          motto: 'Paket Cepat Sampai, Langsung ke Rumahmu',
          body: 'Menjangkau lebih cepat dengan dukungan armada pesawat dan frekuensi penerbangan yang tinggi dari Lion Group.',
        },
        {
          img: '/images/why_us_3.svg',
          title: 'Affordable',
          motto: 'Kirim Tanpa Mikir, Lebih Hemat dan Jemput Tanpa Minimal Biaya',
          body: 'Kirim paket sebanyak apapun tanpa takut dengan biaya pengiriman dan '
              + 'tanpa biaya jemput. Mengirim paket lebih hemat dengan Lion Parcel.',
        },
        {
          img: '/images/why_us_4.svg',
          title: 'Connected',
          motto: 'Menjangkau ke seluruh nusantara.',
          body: 'Dengan lebih dari 7000 mitra tersebar di seluruh nusantara, kirim ke mana saja di seluruh Indonesia maupun ke luar negri.',
        },
      ],
    };
  },
  computed: {
    reverseListData() {
      return [
        this.listData[1], this.listData[0],
        this.listData[this.listData.length - 1], this.listData[2],
      ];
    },
    finalListData() {
      return this.isMobileSize ? this.reverseListData : this.listData;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
