import InternalAPI from '../../baseAxios';

import SEODefault from '@/helpers/SEODefault';
import { isSsr } from '@/helpers/DetectSSR.js';

/** @borrows InternalAPI */
export default class MetaInfoRepository extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = '/v1/meta_info';
  }

  /**
     *
     * @param typeMetaInfo
     * @return {Promise<{data: MetaInfo, err: null}|{data: null, err: ErrorState}>}
     */
  async getMeta(typeMetaInfo) {
    const baseUrl = isSsr() ? process.env.BASE_URL : window.location.href
    try {
      /** @type {AxiosResponse<Object>} */
      const res = await this.$axios.get(`${this.endpoint}/page/${typeMetaInfo}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      SEODefault.attachment_meta_info_photo_url = baseUrl + SEODefault.attachment_meta_info_photo_url;
      return { data: SEODefault, err: null };
    } catch (err) {
      SEODefault.attachment_meta_info_photo_url = baseUrl + SEODefault.attachment_meta_info_photo_url;
      return { data: SEODefault, err: null };
    }
  }
}
