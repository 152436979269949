<template>
  <div class="download-app-section">
    <div>
      <ImageLazy
        :src="`/images/lion-parcel-app.webp`"
        alt="aplikasi jasa pengiriman lion parcel"
        class="download-phone"
        width="600vh"
        height="400vh"
      />
    </div>
    <div class="download-information">
      <h3 class="download-information__item title-section">
        {{ title }}
      </h3>
      <h2
        class="download-information__item"
        style="font-size: 14pt;"
      >
        {{ body }}
      </h2>
      <div class="download-information__item download-information__link">
        <ImageLazy
          id="Download_Google_Play_Homepage"
          :src="`/images/playstore.png`"
          alt="playStore.png"
          class="mr-2"
          width="192px"
          height="65px"
          @click="openLink('android')"
          @error="(event) => setErrorImage(event, 'home-playstore')"
        />
        <ImageLazy
          id="Download_Appstore_Homepage"
          :src="`/images/appstore.png`"
          alt="appStore.png"
          width="192px"
          height="65px"
          @click="openLink('ios')"
          @error="(event) => setErrorImage(event, 'home-appstore')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';

export default {
  name: 'DownloadApp',
  components: { ImageLazy },
  data() {
    return {
      title: 'Download Lion Parcel Sekarang',
      body: 'Aplikasi Jasa Pengiriman yang bisa kirim paket atau barang, serta lacak pengiriman Anda secara realtime dan mudah hanya di Aplikasi Lion Parcel',
      link: {
        android: 'https://play.google.com/store/apps/details?id=com.lionparcel.services.consumer&hl=in',
        ios: 'https://apps.apple.com/id/app/lion-parcel/id1455977134',
      },
    };
  },
  methods: {
    openLink(os) {
      window.open(this.link[os], '_blank');
    },
    setErrorImage(event, addClass) {
      setImageDefault(event, addClass);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
