<template>
  <Modal :use-icon-close="false">
    <div class="success">
      <div class="success__img">
        <img
          src="/images/home/success.svg"
          alt="form_email_success.svg"
        >
      </div>
      <div class="success__title">
        Yay! Berhasil Dapet Diskon 20%
      </div>
      <div class="success__desc">
        Klik “Baca Selengkapnya” untuk cek syarat & ketentuan, ya!
      </div>
      <Button
        text="Baca Selengkapnya"
        class="success__btn"
        type-class="red"
        :custom-padding="true"
        @click="goToPromo"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/new-modal/Modal.vue";
import mixinMobile from "@/misc/mixinMobile.js";
import Button from "@/components/new-button/Button.vue";

export default {
  name: "ModalSuccess",
  components: { Modal, Button },
  mixins: [mixinMobile],
  data() {
    return {}
  },
  methods: {
    goToPromo() {
      this.$router.push("/promo/detail/kirim-paket-di-bawah-1dan10-kg-ada-diskon-20");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/style";

::v-deep .modal-lp__content {
  border-radius: 16px !important;
}

::v-deep .modal-lp__content__body {
  padding: 0 !important;
}

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  letter-spacing: -0.02em;
  padding: 24px 32px;
  width: 417px;
  max-width: 420px;

  @include for-size(mobile) {
    width: 328px;
    max-width: 328px;
  }

  &__img {
    display: flex;
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: $color-base-text;
    text-align: center;
  }

  &__desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: $color-text-small;
    text-align: center;
  }

  &__btn {
    width: 100%;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
  }
}
</style>
