<template>
  <div class="motto-section">
    <div class="motto-section__item">
      <div class="motto-section__item__desc">
        <h1>Jasa pengiriman <span>Tercepat</span> dan <span>Menjangkau</span> seluruh pelosok <span>Indonesia</span>, menggunakan akomodasi pesawat.</h1>
      </div>
      <div class="motto-section__item__desc">
        <h2>
          Dengan Lion Parcel, pengiriman aman, murah, cepat dan terjamin sampai
          ke tempat tujuan di seluruh lokasi Indonesia maupun internasional,
          yang didukung oleh akomodasi pesawat Lion Group.
        </h2>
      </div>
    </div>
    <div class="motto-section__item">
      <ImageLazy
        :src="`/images/plane-lion-air.svg`"
        alt="jasa pengiriman tercepat lion parcel"
        width="350vh"
        height="350vh"
      />
    </div>
  </div>
</template>

<script>
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: 'Motto',
  components: { ImageLazy },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
