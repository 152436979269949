import Cookies from 'js-cookie';

const tokenKey = `${process.env.NODE_ENV}_auth_token_member`;
const captchaKey = `${process.env.NODE_ENV}_captcha_key`;
const agentCaptchaKey = `${process.env.NODE_ENV}_agent_captcha_key`;
export const emailPopup = `${process.env.NODE_ENV}_email_popup`
export const emailPopupClose = `${process.env.NODE_ENV}_email_popup_close`

export const getToken = () => Cookies.get(tokenKey);

export const getCaptchaKey = () => Cookies.get(captchaKey);
export const getAgentCaptchaKey = () => Cookies.get(agentCaptchaKey);

export const setCaptchaKey = (captcha_key) => {
  Cookies.set(captchaKey, captcha_key);
};

export const setAgentCaptchaKey = (captcha_key, isVerified = false) => {
  const oneMinute = isVerified ? null : new Date(new Date().getTime() + 60 * 1000);
  Cookies.set(agentCaptchaKey, captcha_key, {
    expires: oneMinute,
  });
};

export const removeAgentCaptchaKey = () => {
  Cookies.remove(agentCaptchaKey);
};

export const setEmailPopup = ({ email }) => {
  Cookies.set(emailPopup, email, { expires: 30 });
}

export const setEmailPopupClose = () => {
  Cookies.set(emailPopupClose, 'popup_close', { expires: 3 });
}
