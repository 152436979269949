import MetaInfoRepository from '../api/metaInfo';
import { isSsr } from '@/helpers/DetectSSR.js';

class MetaInfoUseCase {
  constructor() {
    /** @type {MetaInfoRepository} */
    this.api = new MetaInfoRepository();
  }

  /**
     *
     * @param typeMetaInfo {string}
     * @return {Promise<{data: MetaInfo, err: null}|{data: null, err: ErrorState}>}
     */
  async getMeta(typeMetaInfo) {
    // ssr meta already handled by server renderering in first request
    if(isSsr()){
      return { data: null, err: null };
    }
    const { data, err } = await this.api.getMeta(typeMetaInfo);
    if (err && err === 'record not found') {
      return { data: null, err: null };
    }
    return { data, err };
  }
}

export default new MetaInfoUseCase();
