import InternalAPI from '../../../../app/baseAxios.js';
import '../types';

/**
 * @borrows InternalAPI
 */
export default class EventAndTipsRepository extends InternalAPI {
  constructor() {
    super();
    /**
     *
     * @type {string}
     */
    this.endpoint = '/v2/event_and_tips/data';
  }

  /**
   * @param {string} query
   * @return {Promise<{data: EventAndTips[], err: Error}>}
   */
  async getAll(query) {
    try {
      /**
       *
       * @type {AxiosResponse<Object>}
       */
      const res = await this.$axios.get(`${this.endpoint}?${query}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data.data.data, err: null };
        }
      }
      return { data: [], err: null };
    } catch (err) {
      let newErr = err.message;
      if (err.response && err.response.data) {
        newErr = err.response.data;
      } else if (!err.response || !err.response.data) {
        newErr = err.message;
      }
      return { data: [], err: this.setErrorMessage(newErr) };
    }
  }
}
