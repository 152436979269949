<template>
  <div class="flex-index">
    <tracking />
    <input-shipping-check-price
      class="cek-tariff"
      @click="goingToTrack"
    />
    <location-agent />
  </div>
</template>

<script>
import './style.scss';
import LocationAgent from './location-agent/locationAgent.vue';
import tracking from './track/Track.vue';
import InputShippingCheckPrice from '../../new-check-price/components/InputShippingCheckPrice';

export default {
  name: 'Feature',
  components: {
    tracking,
    InputShippingCheckPrice,
    LocationAgent,
  },
  methods: {
    goingToTrack() {
      this.$router.push('/tarif');
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 480px) and (max-width: 768px) {
  .cek-tariff {
    margin-bottom: 4%;
  }
}
</style>
