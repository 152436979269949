<template>
  <div id="base-banner">
    <Carousel
      v-if="!isLoadingFetchData"
      :slider-dot-position="setAlignDot"
      :name="carouselName"
      :size="bigScreen ? listChunkBanner.length -1 : listChunkBanner.length"
      :total-all-data="banners.length"
      color-slider="redWithRoundM"
      :shimmer="false"
      :auto-play="true"
      :auto-play-interval="5000"
      :scroll-focus="false"
      :is-absolute="true"
      :is-banner="true"
      margin-slider="auto 35px"
    >
      <template #item>
        <div
          v-for="(slide, index) in listChunkBanner"
          :id="`${carouselName}-${index+1}`"
          :key="`${index}-key`"
          class="slide-number"
        >
          <component
            :is="banner.url.startsWith('http://') || banner.url.startsWith('https://') ? 'a' : banner.url ? 'router-link' : 'div'"
            v-for="(banner, j) in slide"
            :id="`id-${carouselName}-${index}-${j}`"
            :key="`key-${index}-${j}`"
            :class="`slide-item class-${carouselName}`"
            style="cursor: pointer;"
            :href="banner.url"
            :to="banner.url"
            :target="banner.url.startsWith('http://') || banner.url.startsWith('https://') ? '_blank' : '_self'"
          >
            <ImageLazy
              :id="`home-banner-${j}`"
              :src="isMobileWidth ? banner.main_mobile_image : banner.main_desktop_image"
              :alt="banner.alt_text_image"
              class="new-slide-image skeleton-box"
            />
          </component>
        </div>
      </template>
    </Carousel>
    <div
      v-else
      id="skeleton-box"
    />
  </div>
</template>

<script>
import Carousel from '@/components/new-carousel/Carousel';
import ImageLazy from '@/components/ImageLazy/index.vue';
import {
  chunkArray,
  generateChunkCarouselResponsive,
  listenerWindowResize,
  MEDIA,
  removeListenerResize
} from '@/components/new-carousel/util.js'
import BannerUseCase from '@/app/usecase/banner.js';
import { isSsr } from '@/helpers/DetectSSR';

export default {
  name: 'BannerHomeSection',
  components: { Carousel, ImageLazy },
  data() {
    return {
      carouselName: 'bannerHome',
      banners: [],
      isLoadingFetchData: false,
      isMobileWidth: false,
      maxBanner: 5,
      bigScreen: false,
      defaultBannerMobile: '/images/default-banner-sm.svg',
      defaultBannerDesktop: '/images/default-banner-xl.svg'
    }
  },
  computed: {
    listChunkBanner: function () {
      if (this.banners.length < 1) this.pushDefault()
      return chunkArray(this.banners.slice(0, this.maxBanner), 1)
    },
    setAlignDot() {
      return this.isMobileWidth ? 'start' : 'center'
    },
  },
  async created() {
    if (!isSsr()) {
      this.isMobile();
      listenerWindowResize(this.isMobile);
    }
    await this.fetchData()
  },
  beforeDestroy() {
    removeListenerResize(this.isMobile)
  },
  methods: {
    pushDefault() {
      this.banners.push({
        'main_mobile_image' : this.defaultBannerMobile,
        'main_desktop_image' : this.defaultBannerDesktop,
        'alt_text_image' : 'default alt'
      })
    },
    isMobile() {
      this.isMobileWidth = generateChunkCarouselResponsive(window.innerWidth) === MEDIA().MOBILE;
    },
    async fetchData() {
      this.isLoadingFetchData = true
      const data = await BannerUseCase.getAll('home')
      if (data.err === null) {
        setTimeout(() => {
          this.banners = data.data.data
          this.isLoadingFetchData = false
        }, 1000)
      } else {
        this.isLoadingFetchData = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
