<template>
  <div class="popupEmail-input-group">
    <span :class="{required: isRequired && label, optional: isOptional}">{{ label }}</span>
    <Input
      :name="name"
      :placeholder="placeholder"
      :type-input="type"
      :prefix="prefix"
      :custom-class="'prefix ' + name"
      :value="value"
      :is-password="isPassword"
      tabindex="0"
      @input="onInput"
    />
    <div class="popupEmail-input-group__messages">
      <span
        v-show="!isError && note"
        class="popupEmail-input-group__messages__note"
      >
        {{ note }}
      </span>
      <div
        v-show="isError"
        class="popupEmail-input-group__messages__error"
      >
        <img
          src="/images/icon_danger_triangles.svg"
          alt="warning icon"
        >
        <p>{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import Input from '@/components/new-input/Input.vue';

export default {
  components: {Input},
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    prefix: {
      type: String,
      default: "",
    },
    note: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    iconType: {
      type: String,
      default: "",
    },
    isOptional: {
      type: Boolean,
    },
    value: {
      required: true,
      type: String,
      default: "",
    },
    isPassword: {
      type: Boolean,
    },
  },
  data() {
    return {}
  },
  mounted() {
    this.decideErrorField()
  },
  updated() {
    this.decideErrorField()
  },
  methods: {
    onInput(val) {
      this.$emit('input', val)
    },
    decideErrorField() {
      if (this.isError) {
        document.querySelector(`.${this.name}`).querySelector('input').classList.add('error')
      } else {
        document.querySelector(`.${this.name}`).querySelector('input').classList.remove('error')
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/style.scss";

.popupEmail-input-group {
  display: flex;
  flex-direction: column;

  span {
    font-size: 14px;
    line-height: 21px;
    color: $color-base-text;
    font-weight: 600;

    &.required {
      &::after {
        content: "*";
        color: $color-base;
        margin-left: 4px;
      }
    }

    &.optional {
      &::after {
        content: "(Opsional)";
        color: $color-gray-shade;
        margin-left: 4px;
        font-weight: 400;
      }
    }
  }

  &__icon {
    position: absolute;
    right: 10px;
    bottom: 0;
    top: 0;
    margin: auto;
  }

  &__messages {
    margin-top: 8px;
    
    &__error {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 400 !important;
      color: $color-red-6 !important;
    }

    &__note {
      font-size: 14px !important;
      line-height: 21px !important;
      font-weight: 400 !important;
      color: $color-gray-shade !important;
    }
  }

  & ::v-deep .input-wrapper {
    &.prefix {
      display: flex !important;
      align-items: center;
      text-align: left !important;
    }
    
    & .padding-input-prefix {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding-left: 8px !important;
    }

    & .prefix {
      position: relative;
      padding: 7.5px 12px;
      border-right: none;
    }
  }

  & ::v-deep .input-breakpoint-style {
    border-color: $color-gray-pensive-5;

    &.error {
      border:1px solid $color-base !important;
    }
    
    &:focus {
      outline-color: $color-base !important;
      border:1px solid $color-base !important;
      caret-color: $color-base !important;
    }
  }  

  ::v-deep input[type="text"]::placeholder,
  ::v-deep input[type="password"]::placeholder {
    color: $color-gray-shade;
    font-size: 14px!important;
  }

  ::v-deep input[name="password"],
  ::v-deep input[name="confirmPassword"] {
    padding: 8px 35px 8px 10px !important;
  }
}
</style>
