const SEODfault = {
  channel: 'home',
  meta_info_title: 'Lion Parcel Official Website',
  meta_info_description: 'Kiriman Anda Prioritas Kami, Aman & Terpercaya didukung armada lion air group. 📞: 021-80820072  |  📲: 081119600999',
  meta_info_keyword: 'kirim parcel, lion parcel, lion air, kirim paket, kirim aman, paket tercepat, cek ongkir paket, lacak pengiriman, telepon 021-80820072, whatsapp 081119600999',
  meta_info_publish_time: '2021/12/10 19:23T+07:00',
  attachment_meta_info_photo_url: '/images/lion_parcel_logo.png',
};

module.exports = SEODfault;
