<template>
  <div class="main-section">
    <LazyHydrate ssr-only>
      <div class="new-section">
        <!-- Banner  -->
        <div class="new-section__item up">
          <Banner />
        </div>
        <!-- end Banner  -->

        <!-- Feature -->
        <div class="new-section__center">
          <Feature />
        </div>
        <!--  end Feature -->

        <!--  Carousel Promo -->
        <div class="new-section__item down">
          <Promo />
        </div>
        <!-- end Carousel Promo -->
      </div>
    </LazyHydrate>

    <div class="second-section">
      <div class="second-section__item">
        <LazyHydrate ssr-only>
          <DownloadApp />
        </LazyHydrate>
      </div>
      <div class="second-section__item">
        <LazyHydrate ssr-only>
          <EventTips />
        </LazyHydrate>
      </div>
      <div class="second-section__item">
        <LazyHydrate ssr-only>
          <Motto />
        </LazyHydrate>
      </div>
    </div>

    <div class="last-section">
      <WhyUs />
    </div>

    <ModalFormEmail
      v-if="isModalFormEmailOpen"
      @close="closeModalFormEmail"
      @openModalSuccess="openModalSuccess"
    />  

    <ModalSuccess v-if="isModalSuccessOpen" />
  </div>
</template>

<script>
import MetaInfoUseCase from '@/app/metaApi/usecase/metaInfo.js';
import Feature from './feature/index';
import Promo from './promo/Promo';
import mixinMobile from '@/misc/mixinMobile';
import DownloadApp from './section/download-app/index';
import EventTips from './section/event-tips/index';
import Motto from './section/motto/index';
import WhyUs from './section/why-us/index';
import Banner from './banner/banner';
import LazyHydrate from 'vue-lazy-hydration';
import ModalFormEmail from './popup/ModalFormEmail.vue';
import ModalSuccess from './popup/ModalSuccess.vue';
import Cookies from "js-cookie";
import { emailPopup, emailPopupClose, setEmailPopupClose } from "../../misc/Cookies.js"

export default {
  name: 'Homepage',
  components: {
    Feature,
    Promo,
    DownloadApp,
    EventTips,
    Motto,
    WhyUs,
    LazyHydrate,
    Banner,
    ModalFormEmail,
    ModalSuccess,
  },
  mixins: [mixinMobile],
  metaInfo() {
    return this.SEO({
      channel: 'home',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image:this. metaInfo.attachment_meta_info_photo_url
  });
  },
  data() {
    return {
      metaInfo: '',
      isModalFormEmailOpen: false,
      isModalSuccessOpen: false,
    };
  },
  mounted() {
    if (!this.isSsr()){
      window.scrollTo(0, 0);
    }
    if (!Cookies.get(emailPopup) && !Cookies.get(emailPopupClose)) {
      this.isModalFormEmailOpen = true
    }
  },
  created() {
    if (!this.isSsr()) {
      this.fetchMetaInfo();
    }
  },
  methods: {
    fetchMetaInfo() {
      MetaInfoUseCase.getMeta('_')
      .then(resp => {
        if (!resp.err && resp.data) {
          this.metaInfo = resp.data;
        }
      })
      .catch(err => {});
    },
    closeModalFormEmail() {
      setEmailPopupClose()
      this.isModalFormEmailOpen = false
    },
    openModalSuccess() {
      this.isModalSuccessOpen = true
      this.isModalFormEmailOpen = false
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
