import InternalAPI from "@/app/baseAxios.js"
import "../types/popupForm.js"
import { setEmailPopup } from "../../../../misc/Cookies.js"

/**
 * @borrows InternalAPI
 */
export default class ModalForm extends InternalAPI {
    constructor() {
        super()
        /**
         *
         * @type {string}
         */
        this.endpoint = "/v1/email-analytics"
    }

    /**
     *
     * @param payload {Object}
     * @return {Promise<{data: ModalFormResponse, err: ErrorResponse}>}
    */
    async submit(payload) {
        try {
            const res = await this.$axios.post(this.endpoint, payload, {
                headers: {
                    "Authorization": process.env.VUE_APP_TOKEN_URL,
                    "Content-Type": "application/json"
				}
            })
            if (res.status === 200) {
                if (res.data) {
                    setEmailPopup(payload)
                    return {data: res.data, err: null}
                }
            }
            return {data: res.data, err: null}
        } catch (err) {
            return {data: null, err: err.response.data.desc}
        }
    }
}
