/**
 * @enum {String}
 * @type {{ACTIVE: string}}
 */
export const TypeStatus = {
  ACTIVE: 'ACTIVE',
};

/**
 * @enum {String}
 * @type {{LINK: string, CONTENT: string}}
 */
export const TypePromo = {
  LINK: 'Fix Link', // link is in program_url
  CONTENT: 'With Content', // route push by program_slug
};
