<template>
  <div
    :class="[
      'homepage-feature-card keep-height ',
      { 'need-dropdown': isMobileSize },
    ]"
  >
    <input
      v-if="isMobileSize"
      id="dropdown-claim"
      value="claim"
      type="checkbox"
      class="input-checkbox-toggle"
      @click="closeOptionList"
    >
    <label
      v-if="isMobileSize"
      for="dropdown-claim"
      aria-label="Toggle Dropdown Mobile"
    >
      <div class="dropdown-claim" />
    </label>
    <span :class="['bold-text ', { 'new-text-claim': isMobileSize }]">
      Lokasi Agen
    </span>
    <div
      :class="['location-card__input card-feature ', {'dropdown-item-new': isMobileSize}]"
    >
      <Input
        v-model="searchInput"
        placeholder="Kecamatan/Kota"
        name="find-location-agent"
        style="margin-right: 10px;"
        @input="onChange"
      />
      <Button
        text="Cari"
        @click="navigateToLocation"
      />
    </div>
    <OptionSelect
      v-if="showOption"
      decide-class-hover="hover-option-from"
      trigger-class-listener-on-scroll="option-select__content-from"
      style-display="z-index: 20;width: 100%"
      key-value="sub_district_name,district_name"
      :show-loading-search="typing"
      :data-list="listData"
      :capitalize="true"
      @click="chooseMe"
    />
    <div
      :class="[
        'small-info mb-3 ',
        isMobileSize ? 'mt-0 dropdown-item-new' : 'mt-2',
      ]"
    >
      <p>Cari lokasi terdekat</p>
    </div>
  </div>
</template>

<script>
import Input from "@/components/new-input/Input.vue";
import Button from "@/components/new-button/Button.vue";
import OptionSelect from "@/components/new-option-select/OptionSelect.vue";
import AgentDistrictUseCase from "../../../new-agent/app/usecase/agentDistrict";
import mixinMobile from "@/misc/mixinMobile";

export default {
  components: {
    Input,
    Button,
    OptionSelect,
  },
  mixins: [mixinMobile],
  data() {
    return {
      searchInput: "",
      showOption: false,
      typing: false,
      isClose: true,
      listData: {},
      /** @type {District} */
      searchDistrict: {
        district_name: "",
        sub_district_id: 0,
        sub_district_name: "",
      },
      debounce: {
        timeout: 0,
        duration: 1500,
      },
    };
  },
  beforeDestroy() {
    clearTimeout(this.debounce.timeout)
  },
  methods: {
    /** @param value {District} */
    chooseMe(value) {
      this.searchDistrict = value;
      this.showOption = false;
      const picked = value.sub_district_name + ", " + value.district_name;
      this.searchInput = picked.toLowerCase();
    },
    /**
     * @param value {string}
     * @return {Promise<void>}
     */
    onChange(value) {
      this.searchDistrict = {
        district_name: "",
        sub_district_id: 0,
        sub_district_name: "",
      };
      this.showOption = true;
      this.typing = true;
      if (value.length > 0) {
        try {
          if (this.debounce.timeout) {
            clearTimeout(this.debounce.timeout);
          }
          this.debounce.timeout = setTimeout(async () => {
            this.listData = await AgentDistrictUseCase.getAllDistrictBySearch(value);
            this.typing = false;
          }, this.debounce.duration);
        } catch (err) {
          this.typing = false;
        }
      } else {
        this.showOption = false;
        this.typing = false;
      }
    },
    navigateToLocation() {
      if (this.searchDistrict.district_name) {
        localStorage.setItem(
          "location-agent",
          JSON.stringify(this.searchDistrict)
        );
        this.$router.push("/lokasi-agen");
      }
    },
    closeOptionList() {
      this.isClose = !this.isClose;
      if (this.showOption) {
        this.showOption = false;
      } else if (
        !this.showOption &&
        !this.isClose &&
        this.searchDistrict.district_name
      ) {
        this.onChange(this.searchDistrict.district_name);
      } else if (
        !this.showOption &&
        !this.isClose &&
        !this.searchDistrict.district_name
      ) {
        this.onChange(this.searchInput);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/style.scss";
@import "@/assets/css/feature/style";
@import "@/components/new-option-select/style.scss";
@import "../claim/dropdown.scss";

::v-deep input[name='find-location-agent'] {
  text-transform: capitalize;
}
</style>
