import EventAndTipsRepository from '../api/eventAndTips.js';
import '../types';
import { TypeStatus } from '../enum';
import { TimeMapper } from '../../../../misc/timeMapper';

class EventAndTipsUseCase {
  constructor() {
    /** @type {EventAndTipsRepository} */
    this.api = new EventAndTipsRepository();
  }

  /**
     * @return {Promise<{data: EventAndTips[], err: Error}>}
     */
  async getAll(page = 1, perPage = 15, orderBy = 'active_date', date = '') {
    let query = `page=${page}&order_by=${orderBy}&s=active`;
    if (perPage > 40) {
      query += '&per_page=40';
    } else {
      query += `&per_page=${perPage}`;
    }

    const t = new Date();
    const qDate = TimeMapper.SetFormatDate(t.toDateString(), false, ['-', '-', '-'], false, false).split('-').reverse().join('-');

    if (date === '') {
      query += `&date=${qDate}`;
    } else {
      query += `&date=${date}`;
    }

    const resp = await this.api.getAll(query);
    if (resp.data.length > 0) {
      const dateNow = new Date(new Date().toDateString());
      /** @type {EventAndTips[]} */
      const newData = [];
      resp.data.forEach((val) => {
        const active_date = new Date(new Date(val.active_date).toDateString());
        val.created_at = TimeMapper.SetFormatDate(val.created_at, true, [' ', ' ']);
        if (val.status === TypeStatus.ACTIVE && active_date <= dateNow) {
          newData.push(val);
        }
      });
      newData.sort((a, b) => new Date(b.active_date) - new Date(a.active_date));
      resp.data = newData;
    }
    return resp;
  }
}

export default new EventAndTipsUseCase();
