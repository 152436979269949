<template>
  <Modal 
    :custom-icon-close="'close_rounded.svg'"
    @close="closeModalFormEmail"
    @clickOutside="closeModalFormEmail"
  >
    <div class="form-email">
      <div class="form-email__first-section">
        <img
          :src="getImgFirstSection"
          alt="form_email.svg"
        >
      </div>
      <div class="form-email__second-section">
        <div class="form-email__second-section__title">
          Isi Email & Dapatkan Diskon
        </div>
        <div class="form-email__second-section__content">
          Potongan 20%
        </div>
        <div class="form-email__second-section__sub">
          Dapatkan diskon agar pengirimanmu jadi lebih hemat!
        </div>
        <div class="form-email__second-section__form">
          <Input
            v-model="email"
            name="email"
            label=""
            placeholder="Masukkan email"
            tabindex="0"
            class="form-email__second-section__form__input"
            :is-error="emailError"
            :error-message="emailErrorMsg"
          />
          <Button
            text="Kirim"
            class="form-email__second-section__form__btn"
            type-class="red"
            :custom-padding="true"
            :disabled="!email"
            @click="submit()"
          />
        </div>
        <div class="form-email__second-section__notes">
          S&K Berlaku
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/new-modal/Modal.vue";
import mixinMobile from "@/misc/mixinMobile.js";
import Input from "./Input";
import Button from "@/components/new-button/Button.vue";
import usecase from "../app/usecase/popupForm";

export default {
  name: "ModalFormEmail",
  components: { Modal, Input, Button },
  mixins: [mixinMobile],
  data() {
    return {
      email: "",
      emailError: false,
      emailErrorMsg: "",
    };
  },
  computed: {
    getImgFirstSection() {
      return this.windowWidth <= 599
        ? '/images/home/email_mobile.webp'
        : '/images/home/email.webp';
    },
  },
  methods: {
    closeModalFormEmail() {
      this.$emit("close");
    },
    checkEmail() {
      if(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.emailError = false;
        return true;
      }
      else {
        this.emailError = true;
        this.emailErrorMsg = "Format email tidak sesuai. Cek & coba lagi";
      }
    },
    async submit() {
      if (this.checkEmail()) {
        const payload = {
          email: this.email,
        }
        const res = await usecase.submit(payload)
        if (res.data) {
          this.$emit("openModalSuccess")
        }
        if (res.err) { 
          this.emailError = true;
          this.emailErrorMsg = res.err.id;
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/style";

::v-deep .modal-lp__content {
  border-radius: 18px !important;
}

::v-deep .modal-lp__content__close {
  display: flex !important;
  top: 16px !important;
  right: 16px !important;
}

::v-deep .modal-lp__content__body {
  padding: 0 !important;
}

::v-deep input[type=text] {
  font-size: 14px !important;
  height: 40px !important;
}

::v-deep input[type=text]::placeholder {
  font-size: 14px !important;
  color: $color-gray-shade !important;
}

::v-deep .disabled {
  background-color: $color-interpack-3 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  letter-spacing: -0.02em !important;
}

.form-email {
  display: flex;
  flex-direction: row;
  letter-spacing: -0.02em;

  @include for-size(mobile) {
    flex-direction: column;
    width: 328px;
    max-width: 328px;
  }

  &__first-section {
    display: flex;

    img {
      border-radius: 16px 0 0 16px;

      @include for-size(mobile) {
        border-radius: 16px 16px 0 0;
      }
    }
  }

  &__second-section {
    width: 652px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 32px;
    background-image: url('/images/home/right.svg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 16px 16px 0;

    @include for-size(mobile) {
      width: unset;
      padding: 24px 20px;
      background-image: url('/images/home/right_mobile.svg');
      border-radius: 0 0 16px 16px;
    }

    &__title {
      font-weight: 700;
      font-size: 34px;
      line-height: 42px;
      color: $color-base-text;
      margin-bottom: 10px;

      @include for-size(tablet-portrait) {
        font-size: 26px;
        line-height: 25px;
      }

      @include for-size(mobile) {
        font-size: 20px;
        line-height: 25px;
      }
    }

    &__content {
      font-weight: 700;
      font-size: 60px;
      line-height: 74px;
      color: $color-base;
      margin-bottom: 10px;

      @include for-size(tablet-portrait) {
        font-size: 40px;
        line-height: 42px;
      }

      @include for-size(mobile) {
        font-size: 34px;
        line-height: 42px;
      }
    }

    &__sub {
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      color: $color-base-text;
      margin-bottom: 32px;

      @include for-size(tablet-portrait) {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 20px;
      }

      @include for-size(mobile) {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 20px;
      }
    }

    &__form {
      display: flex;
      justify-content: space-between;
      gap: 5px;

      @include for-size(mobile) {
        flex-direction: column;
        gap: 12px;
      }

      &__input {
        width: 100%;
      }

      &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 135px;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }
    }

    &__notes {
      position: absolute;
      right: 32px;
      bottom: 24px;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: $color-gray-shade;

      @include for-size(mobile) {
        position: unset;
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
      }
    }
  }
}
</style>
