import ModalFormRepository from "../api/popupForm";

class ModalFormUseCase {
    constructor() {
        /** @type {ModalFormRepository} */
        this.api = new ModalFormRepository();
    }

    /**
     *
     * @param payload {Object}
     * @return {Promise<{data: {ModalFormResponse}, err: ErrorResponse}>}
    */
    async submit(payload) {
        const {data, err} = await this.api.submit(payload)
        if (data) {
            return {data: data, err: null}
        }
        return {data: null, err: err}
    }
}

export default new ModalFormUseCase()
