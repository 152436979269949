<template>
  <div class="event-tips-section">
    <div class="event-tips-section__item">
      <div>
        Event & Tips
      </div>
    </div>
    <div v-if="!isMobileSize">
      <div
        v-if="!isLoadingToFetch"
        class="animation-fade-in"
      >
        <Carousel
          slider-dot-position="center"
          :name="carouselName"
          :shimmer="false"
          :size="listChunk.length"
          :total-all-data="listData.length"
        >
          <template #item>
            <div
              v-for="(slide, index) in listChunk"
              :id="`${carouselName}-${index+1}`"
              :key="'item-key-' +index"
              class="slide-number event-tips-section-carousel__wrapper-item"
            >
              <div
                v-for="(val, j) in slide"
                :id="`id-${carouselName}-${j}`"
                :key="`key-${index}-${j}`"
                class="slide-item event-tips-section-carousel__wrapper-item__child"
                @click="goToLink(val)"
              >
                <div
                  class="event-tips-section-carousel__wrapper-item__child__image"
                  style="cursor: pointer;"
                >
                  <ImageLazy
                    :id="`corporation-photo-${j}`"
                    :src="val.attachment_corporate_action_sub_photo_url"
                    :alt="val.alt_sub_photo"
                  />
                  <div class="event-tips-section-carousel__wrapper-item__child__image__desc">
                    <span> {{ val.corporate_action_title }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Carousel>
      </div>
      <div
        v-else
        style="text-align: center;"
      >
        <div
          v-for="sk in defaultSize"
          :key="sk"
          class="skeleton-box"
          style="height: 15vw; width: 20vw; margin-right: 8px;"
        />
      </div>
    </div>
    <div
      v-else
      class="event-tips-section__item event-tips-section__mobile"
    >
      <div
        v-for="(val, index) in listDataMobile"
        :key="'event-tips-'+ index"
        class="event-tips-section__mobile__item"
        @click="goToLink(val)"
      >
        <ImageLazy
          :src="val.attachment_corporate_action_sub_photo_url"
          :alt="val.alt_sub_photo"
        />
        <div class="event-tips-section__mobile__item__child">
          <div> {{ val.corporate_action_title }}</div>
          <div> {{ val.created_at }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/new-carousel/Carousel';
import mixinMobile from '@/misc/mixinMobile';
import { chunkArray } from '@/components/new-carousel/util';
import service from '../../app/usecase/eventAndTips';
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: 'EventAndTips',
  components: { Carousel, ImageLazy },
  mixins: [mixinMobile],
  data() {
    return {
      isLoadingFetchData: false,
      defaultSize: 4,
      linkPic: 'https://storage.googleapis.com/lionparcel-web-stg/corporate_action/image/1779-1630564520',
      carouselName: 'event-tips',
      listData: [],
      listDataMobile: [],
    };
  },
  computed: {
    listChunk() {
      if (this.isMobileSize) {
        return chunkArray(this.listData, 1);
      }
      return chunkArray(this.listData, 4);
    },
    isLoadingToFetch() {
      return this.isLoadingFetchData;
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    goToLink(val) {
      this.$router.push(`/info-${val.article_info.toLowerCase()}/${val.corporate_action_slug}`);
    },
    async fetchData() {
      this.isLoadingFetchData = true;
      if (this.$store.state.eventAndTips.events > 0) {
        this.listData = this.$store.state.eventAndTips.events;
        this.isLoadingFetchData = false;
        return
      }
      this.isLoadingFetchData = true;
      const data = await service.getAll(1, 16);
      if (!data.err) {
          this.listData = data.data.slice(0, 16);
          this.listDataMobile = data.data.slice(0, 4);
          this.isLoadingFetchData = false;
          this.$store.commit('SET_EVENTS', this.listData)
      } else {
        this.isLoadingFetchData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
